
footer {
    background-color: white;
    /* position: fixed; */
    padding: 5px;
    /* left: 0; */
    /* bottom: 0; */
    /* width: 100%; */
    text-align: center;
    font-weight: 600;
  }
  