.dropdown {
  background: #f4f4f4;
  border-radius: 5rem;
  /* margin: 2rem; */
  padding: 0.1rem;
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16rem;
  margin-top: 2rem;
}

.dropdown .dropdown-btn {
  padding: 5px 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown .dropdown-content {
  background: #f4f4f4;
  position: absolute;
  top: 110%;
  left: 0;
  /* padding: 0.5rem; */
  width: 100%;
  border-radius: 0.5rem;
}

.dropdown .dropdown-content .dropdown-item {
  border-radius: 0.5rem;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}

.dropdown .dropdown-content .dropdown-item:hover {
  background-color: lightgray;
}

.dropdown:hover {
  background-color: lightgray;
}
