/* Webfont: StrangeLingo-Regular */
@font-face {
  font-family: 'StrangeLingo';
  src: url('../src/fonts/Final.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.custom-font
{
  font-family: "Strangelingo";
}

.vowel-custom-font
{
  font-family: "Strangelingo";
}

.vowel-custom-font-new
{
  font-family: "Strangelingo";
}


/* h1 {
  font-size: 48px;
}

p {
  font-size: 24px;
} */