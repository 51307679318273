.app-consonants-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  overflow: auto;
}

.app-consonants-card {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  /* border: 1px solid; */
  text-align: center;
  padding: 0.5rem;
  /* background: rgb(108,99,255); */
  background: #b3a7dd;
  margin: 0 1rem;
}

.app-consonants-card-child {
  display: flex;
  /* overflow: auto; */
}

.vertical-p {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
