.app-service {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex: 2 1 auto;
}

.app-service-container {
  padding: 1rem;
  text-align: left;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  background-color: rgb(63, 61, 86);
  flex: 1 1 auto;
  /* height: 30rem; */
}

.app-service-pattern {
  flex: 1 1 auto;
  min-width: 30%;
  /* height: 30rem; */
  background: radial-gradient(black 3px, transparent 4px),
    radial-gradient(black 3px, transparent 4px),
    linear-gradient(#fff 4px, transparent 0),
    linear-gradient(
      45deg,
      transparent 74px,
      transparent 75px,
      #a4a4a4 75px,
      #a4a4a4 76px,
      transparent 77px,
      transparent 109px
    ),
    linear-gradient(
      -45deg,
      transparent 75px,
      transparent 76px,
      #a4a4a4 76px,
      #a4a4a4 77px,
      transparent 78px,
      transparent 109px
    ),
    #fff;
  background-size: 109px 109px, 109px 109px, 100% 6px, 109px 109px, 109px 109px;
  /* background-position: 54px 55px, 0px 0px, 0px 0px, 0px 0px, 0px 0px; */
}

.app-service-container > h2 {
  font-weight: bold;
  margin: 10px;
  font-size: 40px;
  line-height: 4rem;
  color: white;
}

.app-service-container > p {
  font-size: 24px;
  font-weight: 300;
  color: white;
  margin-left: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .app-service {
    flex-direction: column;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .app-service {
    flex-direction: column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .app-service {
    flex-direction: row;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .app-service {
    flex-direction: row;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .app-service {
    flex-direction: row;
  }
}
