.alphacard-container {
  margin: 3px;
  width: auto;
  height: 90%;
  display: flex;
  /* flex-wrap: wrap; */
  overflow: hidden;
  /* background:rgba(251, 250, 255, 0.5); */
  /* background: white; */
}

.alphacard-container-child {
  display: flex;
  flex-direction: column;
  margin: 0.1rem;
  /* border: 0.1rem solid black; */
  width: 50%;
  background-color: white;
  flex: 1 1 auto;
}

.alphacard-child {
  /* padding: 0.1rem; */
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}

.voiced:hover {
  background-color: pink;
}

.voiceless:hover {
  background-color: plum;
}
