/* .app-vowels-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  flex-wrap: wrap;
  overflow: auto;
  width: 100%;
}

.app-vowels-card {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
  background: #b3a7dd;
  margin: 0 1rem;
}

.app-vowels-table {
  table-layout: fixed;
  border-spacing: 0.3rem;
  width: 40rem;
  margin: 0.8rem 0.5rem;
}

.app-vowels-container > h2 {
  color: white;
}

.app-vowels-table-tbody-tr td {
  border: 1px solid white;
  height: 4rem;
  width: 5rem;
}

.app-vowels-table th,
.app-vowels-table-tbody th {
  background: white;
  height: 3.5rem;
  width: 7rem;
}

.app-vowels-table-thead-tr th:first-child {
  background: inherit;
  border: none;
}

.app-vowels-container > table > tbody > tr > th {
  background-color: white;
  border: none;
} */

.app-vowels-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  overflow: auto;
}

.app-vowels-card {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
  background: #b3a7dd;
  margin: 0 1rem;
}

.app-vowels-table {
  table-layout: fixed;
  border-spacing: 0.3rem;
  width: fit-content;
  margin: 0.8rem 0.4rem;
}

.app-vowels-table th,
.app-vowels-table-tbody th {
  height: 3rem;
  width: 7rem;
}

.app-vowels-table-tbody-tr td {
  border: 0.01rem solid white;
  height: 3rem;
  width: 7rem;
}

.app-vowels-table th:nth-child(n) {
  background: linear-gradient(
    to left,
    rgba(251, 250, 255, 0.7),
    rgba(251, 250, 255, 0.3)
  );
}

.app-vowels-table-thead th:first-child {
  background: inherit;
}
