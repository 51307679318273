ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.app-header {
  margin: 1rem;
  background-color: white;  
  color: black;
  text-align: center;
}

.app-menu {
  font-size: 1.2rem;
}

.app-logo-text 
{
  text-decoration: none;
  color: black;
  font-weight: 700;
  font-size: 1.4rem;
}

.app-links {
  text-decoration: none;
  color: black;
  font-weight: 600;
}

.app-links:hover {
  text-decoration: underline;
}

.app-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-logo {
  width: 2rem;
  height: 2rem;
  margin: 0.3rem;
}

/* @media (max-width: 769px) {
  .app-menu
  {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

  }
  .app-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
} */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .app-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1 1 auto;
    align-items: flex-start;
  }
  .app-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .app-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex: 1 1 auto;
    align-items: center;
  }
  .app-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .app-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex: 0.3 1 auto;
    align-items: center;
  }
  .app-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
