.vowelcard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  margin: 0.1rem;
}

.justify-right {
  justify-content: flex-end;
}

.justify-left {
  justify-content: flex-start;
}
.vowel-child-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
  margin: 0.1rem;
  padding: 0.3rem 0.2rem 0.2rem 0.2rem;
  background-color: white;
}

.vowel-child {
  width: 33.3%;
  flex: 1 1 auto;
}

.vowelcard-align-middle {
  width: 48%;
}
