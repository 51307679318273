.app-consonants-table {
  table-layout: fixed;
  border-spacing: 0.3rem;
  width: fit-content;
  margin: 0.8rem 0.4rem;
}

.app-consonants-table th,
.app-consonants-table-tbody th {
  height: 3rem;
  width: 6rem;
}

.app-consonants-table-tbody-tr td {
  border: 0.01rem solid white;
  height: 2rem;
  width: 6rem;
}

.app-consonants-table th:nth-child(n) {
  background: linear-gradient(
    to left,
    rgba(251, 250, 255, 0.7),
    rgba(251, 250, 255, 0.3)
  );
}

.app-consonants-table-thead th:first-child {
  background: inherit;
}
