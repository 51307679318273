.app-about {
  min-height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex: 2 1 auto;
}

.app-about-container {
  padding: 1rem;
  text-align: left;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  background-color: white;
  flex: 1 1 auto;
  /* height: 30rem; */
}

.app-about-container > h2 {
  font-weight: bold;
  margin: 10px;
  font-size: 40px;
  line-height: 4rem;
  color: black;
}

.app-about-container > p {
  font-size: 24px;
  font-weight: 300;
  color: gray;
  margin-left: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .app-about {
    flex-direction: column;
  }

  .try{
      --rule : (@grid : 25 / 100%;);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .app-about {
    flex-direction: column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .app-about {
    flex-direction: column;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .app-about {
    flex-direction: column;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .app-about {
    flex-direction: row;
  }
}
