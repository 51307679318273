.app {
  min-height: 100vh;
  /* overflow: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app .dropdown {
  align-self: flex-end;
  color: black;
}

.app-intro-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-intro-img {
  padding: 1rem;
  width: 35rem;
  height: 30rem;
  margin-right: 5rem;
}

.app-intro-content > h1 {
  font-size: 48px;
}

.app-intro-content > p {
  font-size: 24px;
  color: gray;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .app-intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .app-intro-img {
    width: 20rem;
    height: 20rem;
    margin: 0;
  }

  .app-intro-content > h1 {
    font-size: 44px;
  }

  .app-intro-content > p {
    font-size: 22px;
    color: gray;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .app-intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .app-intro-img {
    width: 20rem;
    height: 20rem;
    margin: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .app-intro-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .app-intro-img {
    width: 25rem;
    height: 25rem;
    margin-right: 3rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .app-intro-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .app-intro-img {
    width: 35rem;
    height: 30rem;
    margin-right: 6rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .app-intro-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .app-intro-img {
    width: 35rem;
    height: 30rem;
    margin-right: 6rem;
  }
}
